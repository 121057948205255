<template>
    <div class="anchor-container">
        <div class="dashboard-search-card-container ">
            <le-card width="100%" height="120px">
                <template>
                    <div class="container">
                        <div class="card-title">搜索</div>
                    </div>
                    <div class="search-box" style="padding: 10px 40px;">
                        <a-input-search
                                enter-button="搜索"
                                style="width: 500px;"
                                placeholder="请输入搜索内容"
                                @search="onSearch"
                                :allowClear="true"
                        >
                            <a-select slot="addonBefore" v-model="type" style="width: 160px">
                                <a-select-option value="uname">
                                    房间号/昵称/UID
                                </a-select-option>

                            </a-select>
                        </a-input-search>
                    </div>
                </template>
            </le-card>
        </div>
        <br>
        <!--        推广时间-->
        <!--        创建时间-->
        <!--        <a-range-picker @change="onChangeDate" style="width: 270px;" v-model="value2"/>-->
        <div class="dashboard-table-card-container">
            <le-card width="100%" style="padding-bottom: 20px">
                <template>
                    <div class="container">
                        <div class="table-box">
                            <a-table :columns="columns" :data-source="data" @change="sorterChange">
                                <a slot="name" slot-scope="text">{{ text }}</a>
                                <span slot="customTitle"><a-icon type="smile-o"/> Name</span>
                                <span slot="start_time" slot-scope="tags">
                                    {{tags.start_time}}
                                    <br/>
                                    ~
                                    <br/>
                                    {{tags.end_time}}
                                </span>
                                <span slot="cover" slot-scope="cover">
                                    <img :src="cover.cover" alt="" width="125px">
                                </span>
                                <span slot="uname" slot-scope="uname">
                                    <a :href="'https://space.bilibili.com/'+uname.bili_id" target="_blank">
                                        <p>{{uname.uname}}</p>
                                        <p>BILIID:{{uname.bili_id}}</p>
                                        <p>房间号:{{uname.room_id}}</p>
                                    </a>
                                </span>
                                <span slot="title" slot-scope="title">
                                    <p>{{title.title}}</p>
                                </span>
                                <span slot="ctr" slot-scope="ctr">
                                    <p>{{ctr.ctr}}%</p>
                                </span>
                            </a-table>
                        </div>
                    </div>
                </template>
            </le-card>
        </div>
    </div>
</template>

<script>
    const order = {
        "ascend": 'asc',
        "descend": 'desc',
    };
    const columns = [
        {
            title: '订单状态',
            dataIndex: 'status',
            key: 'status',
        },
        {
            title: '直播封面',
            key: 'cover',
            scopedSlots: {customRender: 'cover'},
        },
        {
            title: '直播标题',
            key: 'title',
            scopedSlots: {customRender: 'title'},
        },
        {
            title: '主播昵称',
            key: 'uname',
            scopedSlots: {customRender: 'uname'},
        },
        {
            title: '粉丝',
            key: 'fans_num',
            dataIndex: 'fans_num',
            sorter: true,
        },
        {
            title: '下单星光',
            key: 'coin_num',
            dataIndex: 'coin_num',
            sorter: true,
        },
        {
            title: '消耗星光',
            key: 'coin_cost',
            dataIndex: 'coin_cost',
            sorter: true,
        },
        {
            title: '曝光量',
            key: 'show_count',
            dataIndex: 'show_count',
            sorter: true,
        },
        {
            title: '进房量',
            key: 'click_count',
            dataIndex: 'click_count',
            sorter: true,
        },
        {
            title: '点击率',
            key: 'ctr',
            scopedSlots: {customRender: 'ctr'},
            sorter: true,
        },

        {
            title: '推广时间',
            key: 'start_time',
            scopedSlots: {customRender: 'start_time'},
        },
    ];

    const data = [];
    export default {
        components: {},
        data() {
            return {
                type: 'uname',
                order,
                data,
                columns,
                value1: [],
                value2: [],
                params: {
                    starTime: '',
                    endTime: '',
                    word: '',
                    type: '',
                    filed: '',
                    order: '',
                    page: 1,//第几页
                    size: 30//每页中显示数据的条数
                }
            };
        },
        computed: {},
        mounted() {
            this.getData();
            // this.$api.get(`v1/user/star/promote`).then((res) => {
            //     if (res.code === 200) {
            //         consoe.log(res);
            //     } else {
            //         this.isLoad2 = false
            //         this.$message.error(res.message)
            //     }
            // });
        },
        methods: {
            getData() {
                this.$api.get(`v1/user/star/promote`, {params: this.params}).then((res) => {
                    if (res.code === 200) {
                        this.data = res.data.data
                    } else {
                        this.$message.error(res.message)
                    }
                });
            },
            sorterChange(pagination, filters, sorter) {
                this.params.filed = sorter.field;
                this.params.order = this.order[sorter.order];
                this.getData();
            },
            onSearch(value) {
                this.params.word = value;
                this.getData();
            }
        }
    };
</script>

<style lang="less" scoped>
    .anchor-container {
        width: 100%;
    }
</style>
